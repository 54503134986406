import "../styles/style.scss";

import "bootstrap/dist/js/bootstrap.bundle";
import 'htmx.org';


import {initUpload} from "./initUpload";
import {initSentry} from "./initSentry";
import {initSidebar} from "./initSidebar";

window.initSentry = initSentry;


window.document.addEventListener("DOMContentLoaded", function () {

    window.console.log("dom ready loaded from base.js");
    window.initUpload = initUpload;
    window.initSidebar = initSidebar;
    window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');
    window.htmx = require('htmx.org');
});
