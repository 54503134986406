
export interface SidebarElements {
    iconBar: HTMLElement | null;
    toggleButton: HTMLElement | null;
    barIcon: HTMLElement | null;
    xIcon: HTMLElement | null;
}

export function getElements(): SidebarElements {
    return {
        iconBar: document.getElementById("iconbar"),
        toggleButton: document.getElementById("toggle-button"),
        barIcon: document.getElementById('barIcon'),
        xIcon: document.getElementById('xIcon')
    };
}

export function toggleSidebar(elements: SidebarElements, isOpen: boolean): void {
    if (!elements.iconBar || !elements.toggleButton || !elements.barIcon || !elements.xIcon) return;

    elements.iconBar.classList.toggle("show", isOpen);
    elements.toggleButton.setAttribute('aria-expanded', isOpen.toString());
    elements.barIcon.classList.toggle("visually-hidden", isOpen);
    elements.xIcon.classList.toggle("visually-hidden", !isOpen);
    localStorage.setItem("sidebarState", isOpen ? "open" : "closed");
}

export function initSidebar(): void {
    const elements = getElements();
    if (!elements.iconBar || !elements.toggleButton) return;

    // Handle active page marking
    document.body.addEventListener('htmx:afterSwap', (event: any) => {
        const setToActive = document.getElementById(event.detail.pathInfo.requestPath);
        if (setToActive) {
            const currentlyActive = document.querySelector(".sidebar-item.active");
            if (currentlyActive) {
                currentlyActive.classList.remove("active");
            }
            setToActive.classList.add("active");
        }
    });

    // Initialize sidebar state
    const sidebarState = localStorage.getItem("sidebarState");
    if (sidebarState === "open") {
        toggleSidebar(elements, true);
    }

    // Toggle button click handler
    elements.toggleButton.addEventListener("click", () => {
        const isCurrentlyOpen = elements.toggleButton?.getAttribute('aria-expanded') === "true";
        toggleSidebar(elements, !isCurrentlyOpen);
    });

    // Click outside handler
    document.addEventListener('click', (event: MouseEvent) => {
        if (elements.iconBar?.classList.contains('show') &&
            !elements.iconBar.contains(event.target as Node) &&
            !elements.toggleButton?.contains(event.target as Node)) {
            toggleSidebar(elements, false);
        }
    });
}
